import React from "react";
import SEO from "../components/seo/js/seo";
import Layout from "../components/layout/js/layout";
import { siteMetadata } from "../../gatsby-config";

const Portfolio = () => (
	<Layout>
		<SEO site={siteMetadata} title="💪 Portfolio" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<article className="h-mt-4 h-mb-4">
						<h2>Portfolio</h2>
						<h3 className="h-mt-4">Beratung</h3>
						<p>
							Beratung & Evaluation von geeigneten Kommunikationsformen, Systemen und Prozessen.
						</p>
						<h3>Konzept</h3>
						<p>Entwicklung von digitalen Anwendungen mit Fokus auf:</p>
						<ul>
							<li>Schaffung einer starken digitalen Identität</li>
							<li>Informationsarchitektur</li>
							<li>Usability</li>
							<li>Webstandards</li>
							<li>Webperformance</li>
						</ul>
						<h3>Entwicklung & Systemintegration</h3>
						<ul>
							<li>Frontend-Entwicklung mit Fokus auf dynamischen Frontends (z.B. mit React/Next.js, Vue.js).</li>
							<li>Implementierung von Web-Applikationen mit unterschiedlichen Web-Architekturen und Technologien. Content-Management, Portal-, E-Commerce und Business-/Custom-Lösungen (Headless/Jamstack oder "konventionell" u.a. mit Directus, strapi, TYPO3 CMS, WordPress, Magento)</li>
						</ul>
					</article>
				</div>
			</div>
		</div>
	</Layout>
);

export default Portfolio;
