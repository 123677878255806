module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Daniel Koch","short_name":"dk","start_url":"/","background_color":"#d9d0ce","theme_color":"#c10d1e","display":"minimal-ui","icon":"src/images/icon.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"5212x512","type":"image/png"}],"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"758ae8db351e2bd793043acd9ed06395"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"10","matomoUrl":"https://matomo.danielkoch.de","siteUrl":"https://danielkoch.de","disableCookies":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
