import React from "react";
import { Link } from "gatsby";
import classNames from "classnames/bind";
import Logo from "../../logo/js/logo";
import Navigation from "../../navigation/js/navigation";
import styles from "../css/header.scss";

const cx = classNames.bind(styles);

const Header = () => (
	<header className={cx("header")}>
		<div className="g-container">
			<div className="g-row">
				<div className="g-col-12 g-col-sm-6">
					<Logo />
				</div>
				<div className="g-col-12 g-col-sm-6">
					<Navigation />
				</div>
			</div>
		</div>
	</header>
);

export default Header;
