import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import classNames from "classnames/bind";
import Header from "../../header/js/header";
import Footer from "../../footer/js/footer";
import { Link } from "gatsby";

// Load global assets
import "../../../assets/loader/loader.scss";

// Load component styles that do not have a js file
import button from "../../button/css/button.scss";

// Load dedicated styles for component
import styles from "../css/layout.scss";

const cx = classNames.bind(styles);

const Layout = ({ children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={data => (
			<>
				<Helmet title={data.site.siteMetadata.title}>
					<html lang="de" />
					<script
						key="jsonld"
						async
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: `
							{
								"@context": "http://www.schema.org",
								"@type": "person",
								"name": "Daniel Koch",
								"jobTitle": "Senior Solution Architect / Senior Frontend Architect",
								"url": "https://danielkoch.de",
								"image": "https://danielkoch.de/image.jpg",
								"description": {data.site.siteMetadata.description},
								"address": {
									"@type": "PostalAddress",
									"streetAddress": "Im Setzling 22a",
									"addressLocality": "Bad Nauheim",
									"addressRegion": "Hessen",
									"postalCode": "61231",
									"addressCountry": "Deutschland"
								}
							}
							`
						}}
					/>
				</Helmet>
				<main className={cx("layout")}>
					<Header />
					<div className={cx("layout__content")}>{children}</div>
					<Footer />
				</main>
			</>
		)}
	/>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
