import React from "react";
import SEO from "../components/seo/js/seo";
import Layout from "../components/layout/js/layout";
import { siteMetadata } from "../../gatsby-config";

const Keys = () => (
	<Layout>
		<SEO site={siteMetadata} title="🤝 Kontakt" robots="noindex,nofollow" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<h2 className="h-mt-4">PGP Keys zur sicheren Kommunikation via E-Mail</h2>
					<h3 className="h-mt-4">info@danielkoch.de</h3>
					<p>
						<a href="/keys/info@danielkoch.de.asc" rel="nofollow">Heruntenladen</a>
					</p>
					<h3 className="h-mt-4">daniel@danielkoch.de</h3>
					<p>
						<a href="/keys/daniel@danielkoch.de.asc" rel="nofollow">Heruntenladen</a>
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default Keys;
