import React from "react";
import SEO from "../components/seo/js/seo";
import Layout from "../components/layout/js/layout";
import { siteMetadata } from "../../gatsby-config";

const Imprint = () => (
	<Layout>
		<SEO site={siteMetadata} title="🧐 Impressum" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<h2 className="h-mt-4">Impressum</h2>
					<h3 className="h-mt-4">Urheberrechte</h3>
					<p>
						© Copyright Daniel Koch, Bad Nauheim (Deutschland).
						<br />
						Alle Rechte vorbehalten. Texte, Bilder, Grafiken, Ton, Animationen und Videos und alle anderen
						Multimediadaten sowie deren Anordnung auf den Webseiten unterliegen dem Schutz des Urheberrechts
						und anderer Schutzgesetze.
					</p>
					<p>
						Der Inhalt dieser Website darf nicht zu kommerziellen Zwecken kopiert, verbreitet, verändert
						oder Dritten zugänglich gemacht werden. Wir weisen daraufhin, dass auf den Websites enthaltene
						Bilder teilweise dem Urheberrecht Dritter unterliegen. Es bedarf in allen Fällen der vorherigen
						schriftlichen Zustimmung von Daniel Koch.
					</p>
					<h3>Haftung</h3>
					<p>
						Diese Website wurde mit größtmöglicher Sorgfalt zusammengestellt. Trotzdem kann keine Gewähr für
						die Fehlerfreiheit, Genauigkeit, Aktualität oder Vollständigkeit der enthaltenen Informationen
						übernommen werden. Jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung
						dieser Webseiten entstehen, wird ausgeschlossen, soweit diese nicht auf Vorsatz oder grober
						Fahrlässigkeit beruhen. Sofern von dieser Website auf Internetseiten verwiesen wird, die von
						Dritten betrieben werden, übernimmt Daniel Koch keine Verantwortung für deren Inhalte.
					</p>
					<h3>Betreiber dieser Website</h3>
					<address>
						Daniel Koch
						<br />
						Im Setzling 22a
						<br />
						61231 Bad Nauheim
						<br />
						<br />
						T: <a href="tel:+49-6032921237">+49 (0) 60 32 / 92 12 37</a>
						<br />
						F: +49 (0) 60 32 / 92 12 37
					</address>
					<p>
						<strong>Steuernummer:</strong>
						<br />
						016 836 607693
						<br />
						<strong>USt-IdNr.:</strong>
						<br />
						DE206468202
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default Imprint;
