import React from "react";
import classNames from "classnames/bind";
import styles from "../css/topics.scss";

const cx = classNames.bind(styles);

const topics = [
	{
		name: "Adobe Experience Cloud"
	},
	{
		name: "API"
	},
	{
		name: "Astro"
	},
	{
		name: "Azure"
	},
	{
		name: "BEM"
	},
	{
		name: "Beratung & Evaluation"
	},
	{
		name: "Best Practice"
	},
	{
		name: "Bitbucket"
	},
	{
		name: "CI/CD"
	},
	{
		name: "Code-Reviews"
	},
	{
		name: "Confluence"
	},
	{
		name: "Continuous Deployment (CD)"
	},
	{
		name: "Continuous Integration (CI)"
	},
	{
		name: "Core Web Vitals"
	},
	{
		name: "Cross-browser"
	},
	{
		name: "CSS"
	},
	{
		name: "Design Systems"
	},
	{
		name: "Development Setups"
	},
	{
		name: "Directus CMS"
	},
	{
		name: "Figma"
	},
	{
		name: "Frontend"
	},
	{
		name: "Integration"
	},
	{
		name: "Setup"
	},
	{
		name: "Tooling"
	},
	{
		name: "Workflow"
	},
	{
		name: "Frontend-Konzeption und -Architektur"
	},
	{
		name: "GatsbyJS"
	},
	{
		name: "GIT"
	},
	{
		name: "GitHub"
	},
	{
		name: "GitLab"
	},
	{
		name: "GraphQL"
	},
	{
		name: "Herzblut"
	},
	{
		name: "Beratung & Evaluation"
	},
	{
		name: "HTML/XML/JSON, ..."
	},
	{
		name: "Humor"
	},
	{
		name: "Interdisziplinäre Projektarbeit"
	},
	{
		name: "Jamstack"
	},
	{
		name: "JavaScript"
	},
	{
		name: "Jenkins"
	},
	{
		name: "JIRA"
	},
	{
		name: "KirbyCMS"
	},
	{
		name: "Lead Frontend"
	},
	{
		name: "Liebe zum Detail"
	},
	{
		name: "Lighthouse"
	},
	{
		name: "Linting"
	},
	{
		name: "Linux"
	},
	{
		name: "MacOS"
	},
	{
		name: "Magento"
	},
	{
		name: "Maintainability"
	},
	{
		name: "Metadata"
	},
	{
		name: "Microdata"
	},
	{
		name: "Microsoft Dynamics D365 Commerce"
	},
	{
		name: "Mobile First"
	},
	{
		name: "Multi-Screen Applikationen"
	},
	{
		name: "Next.js"
	},
	{
		name: "Nextcloud"
	},
	{
		name: "Nexus"
	},
	{
		name: "Node.js"
	},
	{
		name: "NPM"
	},
	{
		name: "Offline"
	},
	{
		name: "OO"
	},
	{
		name: "Open-Source"
	},
	{
		name: "PHP"
	},
	{
		name: "Pipelines"
	},
	{
		name: "Präsentationen"
	},
	{
		name: "Progressive Web App (PWA)"
	},
	{
		name: "Projektplanung"
	},
	{
		name: "QA & Testing"
	},
	{
		name: "Quality Gate"
	},
	{
		name: "React"
	},
	{
		name: "Refactoring"
	},
	{
		name: "Requirements Engineering"
	},
	{
		name: "Responsive Web Design (RWD)"
	},
	{
		name: "REST"
	},
	{
		name: "SAP Commerce"
	},
	{
		name: "Sass/SCSS"
	},
	{
		name: "Sitespeed"
	},
	{
		name: "Sonar"
	},
	{
		name: "SPA"
	},
	{
		name: "Spass"
	},
	{
		name: "Spezifikation"
	},
	{
		name: "SSG"
	},
	{
		name: "SSR"
	},
	{
		name: "Stability"
	},
	{
		name: "Storybook"
	},
	{
		name: "strapi CMS"
	},
	{
		name: "Tailwind CSS"
	},
	{
		name: "Teamleitung"
	},
	{
		name: "Teamwork"
	},
	{
		name: "Technische Konzeption"
	},
	{
		name: "TypeScript"
	},
	{
		name: "TYPO3 CMS"
	},
	{
		name: "Usability"
	},
	{
		name: "Vue.js"
	},
	{
		name: "Web-Strategien"
	},
	{
		name: "Web-Applikation"
	},
	{
		name: "Web-Audits"
	},
	{
		name: "webpack"
	},
	{
		name: "WebPageTest"
	},
	{
		name: "Webperformance (WPF)"
	},
	{
		name: "Webstandards (W3C)"
	},
	{
		name: "WordPress"
	}
];

class Topics extends React.Component {
	constructor() {
		super();
	}

	renderTopics() {
		if (!topics) return;

		const topic = topics.map((obj, i) => {
			return (
				<li key={i} className={cx("topics__item")}>
					<span>{obj.name}</span>
				</li>
			);
		});

		return <>{topic}</>;
	}
	render() {
		return (
			<article className={cx("topics")}>
				<ul className={cx("topics__list")}>{this.renderTopics()}</ul>
			</article>
		);
	}
}
export default Topics;
