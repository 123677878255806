import React from "react";
import Lightbox from "react-images";
import classNames from "classnames/bind";
import styles from "../css/image-gallery.scss";

const cx = classNames.bind(styles);

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

class ImageGallery extends React.Component {
	constructor() {
		super();

		this.state = {
			lightboxIsOpen: false,
			currentImage: 0
		};

		this.imageCountSeparator = " / ";

		this.closeLightbox = this.closeLightbox.bind(this);
		this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
		this.gotoImage = this.gotoImage.bind(this);
		this.handleClickImage = this.handleClickImage.bind(this);
		this.openLightbox = this.openLightbox.bind(this);
	}
	handleClickImage() {
		if (this.state.currentImage === this.props.images.length - 1) return;

		this.gotoNext();
	}
	openLightbox(e, i) {
		e.preventDefault();
		this.setState({
			currentImage: i,
			lightboxIsOpen: true
		});
	}
	closeLightbox() {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false
		});
	}
	gotoPrevious() {
		this.setState({
			currentImage: this.state.currentImage - 1
		});
	}
	gotoNext() {
		this.setState({
			currentImage: this.state.currentImage + 1
		});
	}
	gotoImage(i) {
		this.setState({
			currentImage: i
		});
	}
	renderImages() {
		const { images } = this.props;

		if (!images) return;

		const image = images.map((obj, i) => {
			return (
				<a key={i} href={obj.src} onClick={e => this.openLightbox(e, i)}>
					<img src={obj.thumbnail} alt={obj.alt} className={cx("image-gallery__file")} />
				</a>
			);
		});

		return <>{image}</>;
	}
	render() {
		return (
			<div className={cx("image-gallery image-gallery--overlap")}>
				{this.renderImages()}
				<Lightbox
					images={this.props.images}
					currentImage={this.state.currentImage}
					isOpen={this.state.lightboxIsOpen}
					imageCountSeparator={this.imageCountSeparator}
					onClickImage={this.handleClickImage}
					onClickNext={this.gotoNext}
					onClickPrev={this.gotoPrevious}
					onClickThumbnail={this.gotoImage}
					onClose={this.closeLightbox}
				/>
			</div>
		);
	}
}
export default ImageGallery;
