import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import Twitter from "./twitter";
import Facebook from "./facebook";

const SEO = ({ title = null, description = null, robots = null, image = null, pathname = null, article = false }) => (
	<StaticQuery
		query={graphql`
			query SEOQuery {
				site {
					siteMetadata {
						defaultTitle: title
						titleTemplate
						defaultDescription: description
						defaultRobots: robots
						siteUrl: url
						defaultImage: image
						twitterUsername
						facebookAppID
					}
				}
			}
		`}
		// eslint-disable-next-line complexity
		render={({
			site: {
				siteMetadata: {
					defaultTitle,
					titleTemplate,
					defaultDescription,
					defaultRobots,
					siteUrl,
					defaultImage,
					twitterUsername,
					facebookAppID
				}
			}
		}) => {
			const seo = {
				title: title || defaultTitle,
				description: description || defaultDescription,
				robots: robots || defaultRobots,
				image: `${siteUrl}/${image || defaultImage}`,
				url: `${siteUrl}${pathname || "/"}`
			};

			return (
				<>
					<Helmet title={seo.title} titleTemplate={titleTemplate}>
						<meta name="description" content={seo.description} />
						<meta name="image" content={seo.image} />
						<meta name="robots" content={seo.robots} />
						<meta name="googlebot" content={seo.robots} />
					</Helmet>
					<Facebook
						pageUrl={seo.url}
						type={article ? "article" : null}
						title={seo.title}
						description={seo.description}
						image={seo.image}
						appID={facebookAppID}
					/>
					<Twitter
						username={twitterUsername}
						title={seo.title}
						description={seo.description}
						image={seo.image}
					/>
				</>
			);
		}}
	/>
);

SEO.propTypes = {
	article: PropTypes.bool,
	description: PropTypes.string,
	image: PropTypes.string,
	pathname: PropTypes.string,
	robots: PropTypes.string,
	title: PropTypes.string
};

export default SEO;
