import React from "react";
import classNames from "classnames/bind";
import { Link } from "gatsby";
import styles from "../css/navigation.scss";

const cx = classNames.bind(styles);

const Navigation = () => (
	<nav className={cx("navigation")}>
		<ul className={cx("navigation__list")}>
			<li className={cx("navigation__item")}>
				<Link
					to="/portfolio"
					className={cx("navigation__link")}
					activeClassName={cx("navigation__link--active")}
				>
					Portfolio
				</Link>
			</li>
			<li className={cx("navigation__item")}>
				<Link to="/kontakt" className={cx("navigation__link")} activeClassName={cx("navigation__link--active")}>
					Kontakt
				</Link>
			</li>
		</ul>
	</nav>
);

export default Navigation;
