import React from "react";
import SEO from "../components/seo/js/seo";
import Layout from "../components/layout/js/layout";
import { siteMetadata } from "../../gatsby-config";

const Terms = () => (
	<Layout>
		<SEO site={siteMetadata} title="🧐 Allgemeine Geschäftsbedingungen (AGB)" robots="noindex,nofollow" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<h2 className="h-mt-4">Allgemeine Geschäftsbedingungen (AGB)</h2>
					<h3 className="h-mt-4">Standardverträge</h3>
					<p>
						<a
							href="/pdfs/Daniel Koch, Allgemeine Geschäftsbedingungen (AGB), Mischverträge, 10-2013.pdf"
							rel="nofollow"
						>
							Heruntenladen
						</a>
					</p>
					<h3 className="h-mt-4">Erweiterung für Domain- und Webhosting</h3>
					<p>
						<a
							href="/pdfs/Daniel Koch, Weitere Allgemeine Geschäftsbedingungen (AGB) für Verträge über Domain- und Webhosting, 10-2013"
							rel="nofollow"
						>
							Heruntenladen
						</a>
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default Terms;
