import React from "react";
import SEO from "../components/seo/js/seo";
import Layout from "../components/layout/js/layout";
import { siteMetadata } from "../../gatsby-config";

const Privacy = () => (
	<Layout>
		<SEO site={siteMetadata} title="🧐 Impressum" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<h2 className="h-mt-4">Datenschutz</h2>
					<h3 className="h-mt-4">
						1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen
						Datenschutzbeauftragten
					</h3>
					<p>
						Diese Datenschutz-Information gilt für die Datenverarbeitung durch: Verantwortlich: Daniel Koch,
						Im Setzling 22a, 61231 Bad Nauheim, Deutschland, E-Mail:{" "}
						<a href="mailto:%69%6E%66%6F%40%64%61%6E%69%65%6C%6B%6F%63%68%2E%64%65">info@danielkoch.de</a>,
						Telefon: +49 (0)6032-921237.
					</p>
					<h3>
						2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung
					</h3>
					<p>
						a) Beim Besuch der Website
						<br />
						Beim Aufrufen unserer Website danielkoch.de werden durch den auf Ihrem Endgerät zum Einsatz
						kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese
						Informationen werden in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne
						Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
					</p>
					<ul>
						<li>IP-Adresse des anfragenden Rechners,</li>
						<li>Datum und Uhrzeit des Zugriffs,</li>
						<li>Name und URL der abgerufenen Datei,</li>
						<li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
						<li>
							verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres
							Access-Providers.
						</li>
					</ul>
					<p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>
					<ul>
						<li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
						<li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
						<li>Auswertung der Systemsicherheit und -stabilität sowie</li>
						<li>zu weiteren administrativen Zwecken.</li>
					</ul>
					<p>
						Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser
						berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall
						verwenden wir die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
					</p>
					<p>
						Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein. Nähere
						Erläuterungen dazu erhalten Sie unter den Ziff. 4 und 5 dieser Datenschutzerklärung.
					</p>
					<h3>3. Weitergabe von Daten</h3>
					<p>
						Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden
						aufgeführten Zwecken findet nicht statt.
					</p>
					<p>Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>
					<ul>
						<li>
							Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
						</li>
						<li>
							die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder
							Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass
							Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,
						</li>
						<li>
							für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche
							Verpflichtung besteht, sowie
						</li>
						<li>
							dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von
							Vertragsverhältnissen mit Ihnen erforderlich ist.
						</li>
					</ul>
					<h3>4. Cookies</h3>
					<p>
						Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr
						Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.)
						gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem Endgerät keinen
						Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
					</p>
					<p>
						In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch
						eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis
						von Ihrer Identität erhalten.
					</p>
					<p>
						Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer
						zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne
						Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite
						automatisch gelöscht.
					</p>
					<p>
						Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies
						ein, die für einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden.
						Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch
						erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie getätigt
						haben, um diese nicht noch einmal eingeben zu müssen.
					</p>
					<p>
						Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und
						zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten (siehe Ziff. 5). Diese Cookies
						ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie
						bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch
						gelöscht.
					</p>
					<p>
						Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer
						berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DSGVO erforderlich.
					</p>
					<p>
						Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so
						konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis
						erscheint, bevor ein neuer Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann
						jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website nutzen können.
					</p>
					<h3>5. Analyse-Tools</h3>
					<h4>Tracking-Tools</h4>
					<p>
						Die im Folgenden aufgeführten und von uns eingesetzten Tracking-Maßnahmen werden auf Grundlage des
						Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen
						wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer Webseite sicherstellen.
						Zum anderen setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Webseite statistisch zu
						erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Interessen
						sind als berechtigt im Sinne der vorgenannten Vorschrift anzusehen.
					</p>
					<p>
						Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir {" "}
						<a href="https://matomo.org/" target="_blank" rel="noopener">
							Matomo
						</a>{" "}
						In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt.
						Diese Nutzerprofile enthalten Informationen über Ihre Benutzung dieser Website, im Detail:
					</p>
					<ul>
						<li>Browser-Typ/-Version</li>
						<li>verwendetes Betriebssystem</li>
						<li>Referrer-URL (die zuvor besuchte Seite)</li>
						<li>Hostname des zugreifenden Rechners (IP-Adresse)</li>
						<li>Uhrzeit der Serveranfrage</li>
					</ul>
					<p>
						Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
						zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
						Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser
						Internetseiten zu erbringen. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist
						(IP-Masking).
					</p>
					<div id="matomo-opt-out"></div>
					<script src="https://matomo.danielkoch.de/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&backgroundColor=FFFFFF&fontColor=666666&fontSize=16px&fontFamily=-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji&showIntro=1"></script>
					<h3>6. Social Media Plug-ins</h3>
					<p>
						Wir setzen auf unserer Website auf Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO das Plug-in
						sozialer Netzwerke ein, um unsere Dienstleistungen hierüber bekannter zu machen. Der
						dahinterstehende werbliche Zweck ist als berechtigtes Interesse im Sinne der DSGVO anzusehen.
						Die Verantwortung für den datenschutzkonformen Betrieb ist durch deren jeweiligen Anbieter zu
						gewährleisten.
					</p>
					<h4>a) Facebook</h4>
					<p>
						Auf unserer Website kommen Social-Media Plug-ins von Facebook zum Einsatz, um deren Nutzung
						persönlicher zu gestalten. Hierfür nutzen wir den „LIKE“ oder „TEILEN“-Button. Es handelt sich
						dabei um ein Angebot von Facebook.
					</p>
					<p>
						Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plug-in enthält, baut Ihr
						Browser eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plug-ins wird
						von Facebook direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden.
					</p>
					<p>
						Durch die Einbindung der Plug-ins erhält Facebook die Information, dass Ihr Browser die
						entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Konto
						besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information (einschließlich Ihrer
						IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in den USA übermittelt
						und dort gespeichert.
					</p>
					<p>
						Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Website Ihrem Facebook-Konto
						direkt zuordnen. Wenn Sie mit den Plug-ins interagieren, zum Beispiel den „LIKE“ oder
						„TEILEN“-Button betätigen, wird die entsprechende Information ebenfalls direkt an einen Server
						von Facebook übermittelt und dort gespeichert. Die Informationen werden zudem auf Facebook
						veröffentlicht und Ihren Facebook-Freunden angezeigt.
					</p>
					<p>
						Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten
						Gestaltung der Facebook-Seiten benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und
						Beziehungsprofile erstellt, z. B. um Ihre Nutzung unserer Website im Hinblick auf die Ihnen bei
						Facebook eingeblendeten Werbeanzeigen auszuwerten, andere Facebook-Nutzer über Ihre Aktivitäten
						auf unserer Website zu informieren und um weitere mit der Nutzung von Facebook verbundene
						Dienstleistungen zu erbringen.
					</p>
					<p>
						Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten Ihrem
						Facebook-Konto zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Facebook
						ausloggen.
					</p>
					<p>
						Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch
						Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer
						Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen (
						<a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener">
							https://www.facebook.com/about/privacy/
						</a>
						) von Facebook.
					</p>
					<h4>b) Twitter</h4>
					<p>
						Auf unseren Internetseiten sind Plugins bzw. Services des Kurznachrichtennetzwerks der Twitter
						Inc. (Twitter) integriert.
					</p>
					<p>
						Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plugin enthält, wird eine
						direkte Verbindung zwischen Ihrem Browser und dem Twitter-Server hergestellt. Twitter erhält
						dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den
						Twitter „tweet-Button“ anklicken, während Sie in Ihrem Twitter-Account eingeloggt sind, können
						Sie die Inhalte unserer Seiten auf Ihrem Twitter-Profil verlinken. Dadurch kann Twitter den
						Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter
						der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter
						erhalten.
					</p>
					<p>
						Wenn Sie nicht wünschen, dass Twitter den Besuch unserer Seiten zuordnen kann, loggen Sie sich
						bitte aus Ihrem Twitter-Benutzerkonto aus.
					</p>
					<p>
						Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von{" "}
						<a href="https://twitter.com/privacy" target="_blank" rel="noopener">
							Twitter
						</a>
						.
					</p>
					<h3>7. Betroffenenrechte</h3>
					<p>Sie haben das Recht:</p>
					<ul>
						<li>
							gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu
							verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der
							personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten
							offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf
							Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
							Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie
							über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und
							ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;
						</li>
						<li>
							gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer
							bei uns gespeicherten personenbezogenen Daten zu verlangen;
						</li>
						<li>
							gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu
							verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
							und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen
							Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
							erforderlich ist;
						</li>
						<li>
							gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
							verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung
							unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen,
							Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
							benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
						</li>
						<li>
							gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
							strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an
							einen anderen Verantwortlichen zu verlangen;
						</li>
						<li>
							gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu
							widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung
							beruhte, für die Zukunft nicht mehr fortführen dürfen und
						</li>
						<li>
							gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie
							sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes
							oder des unter Ziff. 1 angegebenen Anbietersitzes wenden.
						</li>
					</ul>
					<h3>8. Widerspruchsrecht</h3>
					<p>
						Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs.
						1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch
						gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen,
						die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung
						richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
						besonderen Situation von uns umgesetzt wird.
					</p>
					<p>
						Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an{" "}
						<a href="mailto:%69%6E%66%6F%40%64%61%6E%69%65%6C%6B%6F%63%68%2E%64%65">info@danielkoch.de</a>.
					</p>
					<h3>9. Datensicherheit</h3>
					<p>
						Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer)
						in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt
						wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser
						keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie
						zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird,
						erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in
						der unteren Statusleiste Ihres Browsers.
					</p>
					<p>
						Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen,
						um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen
						Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere
						Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.
					</p>
					<h3>10. Aktualität und Änderung dieser Datenschutzerklärung</h3>
					<p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018.</p>
					<p>
						Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter
						gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese
						Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der
						Website unter https://danielkoch.de von Ihnen abgerufen und ausgedruckt werden.
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default Privacy;
