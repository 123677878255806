import React from "react";
import { Link } from "gatsby";
import classNames from "classnames/bind";
import styles from "../css/footer.scss";

const cx = classNames.bind(styles);

const today = new Date();
const currentYear = today.getFullYear();

const Footer = () => (
	<footer className={cx("footer")}>
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<ul className={cx("footer__list")}>
						<li className={cx("footer__item")}>
							<span className={cx("footer__link")}>
								© {currentYear} Daniel Koch
								<span className={cx("footer__link footer__link--only-md")}>:</span>{" "}
								<span className={cx("footer__link footer__link--only-md")}>
									Beratung, Konzept, Entwicklung
								</span>
							</span>
						</li>
						<li className={cx("footer__item")}>
							<Link to="/datenschutz" className={cx("footer__link")}>
								Datenschutz
							</Link>
						</li>
						<li className={cx("footer__item")}>
							<Link to="/impressum" className={cx("footer__link")}>
								Impressum
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
