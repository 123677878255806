import React from "react";
import classNames from "classnames/bind";
import ImageGallery from "../../image-gallery/js/image-gallery";
import styles from "../css/widget.scss";
import imageSrc from "../../../images/Daniel_Koch_Portrait_2017_1024x800.jpg";
import imageThumbnail from "../../../images/Daniel_Koch_Portrait_2017_415x258.jpg";

const cx = classNames.bind(styles);

const images = [
	{
		src: imageSrc,
		thumbnail: imageThumbnail,
		alt: "Bild von Daniel Koch",
		caption: "Daniel Koch Portrait"
	}
];

const Widget = () => (
	<div className={cx("widget")}>
		<div className={cx("widget__inner")}>
			<h3>Über Daniel Koch</h3>
			<ImageGallery images={images} />
			<p className={cx("widget__text")}>
				Geboren im Oktober 1980. Absolvierte in den Jahren 2000 - 2003 eine Ausbildung zum Mediengestalter
				Digital- und Printmedien. Danach folgte ein Studium an der{" "}
				<a href="https://www.h-da.de/" target="_blank" rel="noopener">
					Hochschule Darmstadt
				</a>{" "}

				mit dem Abschluss Diplom Media System Designer. Seit dem Jahr 2000 ist er selbständig sowie als Senior
				Solution Architect bei{" "}
				<a href="https://arineo.com/" target="_blank" rel="noopener">
					Arineo
				</a>{" "}
				im Bereich Webentwicklung tätig. Zuvor arbeitete er als Senior
				Frontend Architect bei{" "}
				<a href="https://merkleinc.de/" target="_blank" rel="noopener">
					Merkle (ehemals Namics)
				</a>{" "}
				und als Principal Software Engineer Frontend bei{" "}
				<a href="https://www.netcentric.biz/" target="_blank" rel="noopener">
					Netcentric
				</a>
				.
			</p>
			<p className={cx("widget__text")}>
				Seine Schwerpunkte sind kundenspezifische Webapplikations-Lösungen und dynamische Frontends. Besonders
				am Herzen liegt ihm interdisziplinäre Projektarbeit und die Entwicklung von Multi-Screen Websites.
			</p>
			<p className={cx("widget__text")}>
				In seiner Freizeit fährt er Mountainbike (MTB), Snowboard, läuft und spielt Schlagzeug (
				<a href="https://www.soulclub.de/" target="_blank" rel="noopener">
					SOULCLUB
				</a>{" "}
				und{" "}
				<a href="https://www.themuffintops.de/" target="_blank" rel="noopener">
					The Muffin Tops
				</a>
				). Er wohnt zur Zeit in Bad Nauheim bei Frankfurt am Main.
			</p>
		</div>
	</div>
);

export default Widget;
