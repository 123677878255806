import React from "react";
import { Link } from "gatsby";
import classNames from "classnames/bind";
import styles from "../css/logo.scss";
import logo from "../img/danielkoch.svg";

const cx = classNames.bind(styles);

const Logo = () => (
	<>
		<Link to="/" className={cx("logo")}>
			<img className={cx("logo__image")} src={logo} alt="Daniel Koch" />
		</Link>
	</>
);

export default Logo;
