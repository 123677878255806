module.exports = {
	siteMetadata: {
		title: "😀",
		description:
			"Website von Daniel Koch aus Bad Nauheim bei Frankfurt am Main. Inhalte sind: Techniken und Themen rund um die Entwicklung kundenspezifischer Webapplikations-Lösungen und dynamischer Frontends, interdisziplinäre Projektarbeitsweise und die Herausforderungen von Multi-Screen Websites.",
		keywords:
			"daniel koch multiscreen website entwicklung beratung frontend css html javascript mobile interdisziplinär wordpress",
		titleTemplate: "%s | Daniel Koch | Web: Beratung, Konzept, Entwicklung",
		robots: "index,follow",
		url: "https://danielkoch.de",
		siteUrl: "https://danielkoch.de",
		image: "image.jpg",
		owner: "Daniel Koch",
		twitterUsername: "@danielkochde",
		facebookAppID: "",
	},
	plugins: [
		"gatsby-plugin-react-helmet",
		{
			resolve: `gatsby-source-filesystem`,
			options: {
				name: `images`,
				path: `${__dirname}/src/images`,
			},
		},
		"gatsby-plugin-image",
		"gatsby-plugin-sharp",
		"gatsby-transformer-sharp",
		{
			resolve: `gatsby-plugin-manifest`,
			options: {
				name: "Daniel Koch",
				short_name: "dk",
				start_url: "/",
				background_color: "#d9d0ce",
				theme_color: "#c10d1e",
				display: "minimal-ui",
				icon: "src/images/icon.png",
				icons: [
					{
						src: `favicons/icon-48x48.png`,
						sizes: `48x48`,
						type: `image/png`,
					},
					{
						src: `favicons/icon-72x72.png`,
						sizes: `72x72`,
						type: `image/png`,
					},
					{
						src: `favicons/icon-96x96.png`,
						sizes: `96x96`,
						type: `image/png`,
					},
					{
						src: `favicons/icon-144x144.png`,
						sizes: `144x144`,
						type: `image/png`,
					},
					{
						src: `favicons/icon-192x192.png`,
						sizes: `192x192`,
						type: `image/png`,
					},
					{
						src: `favicons/icon-256x256.png`,
						sizes: `256x256`,
						type: `image/png`,
					},
					{
						src: "icons/icon-384x384.png",
						sizes: "384x384",
						type: "image/png",
					},
					{
						src: `favicons/icon-512x512.png`,
						sizes: `5212x512`,
						type: `image/png`,
					},
				],
				legacy: false, // this will not add apple-touch-icon links to <head>
			},
		},
		{
			resolve: `gatsby-plugin-sass`,
			options: {
				sassOptions: {
					precision: 6,
				},
			},
		},
		{
			resolve: "gatsby-plugin-matomo",
			options: {
				siteId: "10",
				matomoUrl: "https://matomo.danielkoch.de",
				siteUrl: "https://danielkoch.de",
				disableCookies: true,
			},
		},
		"gatsby-plugin-sitemap",
		"gatsby-plugin-offline",
	],
};
