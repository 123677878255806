import React from "react";
import SEO from "../components/seo/js/seo";
import Layout from "../components/layout/js/layout";
import { siteMetadata } from "../../gatsby-config";

const Contact = () => (
	<Layout>
		<SEO site={siteMetadata} title="🤝 Kontakt" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col">
					<h2 className="h-mt-4">Kontakt</h2>
					<p className="h-mt-4 h-mb-2">Erreichen Sie mich unter:</p>
				</div>
			</div>
			<div className="g-row">
				<div className="g-col-sm-6">
					<article className="h-mb-4">
						<ul>
							<li>
								<a href="mailto:%69%6E%66%6F%40%64%61%6E%69%65%6C%6B%6F%63%68%2E%64%65">E-Mail</a>
							</li>
							<li>
								<a href="https://github.com/danielkoch" target="_blank" rel="noopener">
									Github
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/danielkochde" target="_blank" rel="noopener">
									Facebook
								</a>
							</li>
							<li>
								<a href="https://twitter.com/danielkochde" target="_blank" rel="noopener">
									Twitter
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/in/danielkochde/" target="_blank" rel="noopener">
									LinkedIn
								</a>
							</li>
							<li>
								<a href="https://www.xing.com/profile/Daniel_Koch" target="_blank" rel="noopener">
									XING
								</a>
							</li>
						</ul>
					</article>
				</div>
				<div className="g-col-sm-6">
					<address>
						Daniel Koch
						<br />
						Im Setzling 22a
						<br />
						61231 Bad Nauheim
						<br />
						<br />
						T: <a href="tel:+49-6032921237">+49 (0) 60 32 / 92 12 37</a>
						<br />
						F: +49 (0) 60 32 / 92 12 37
					</address>
				</div>
			</div>
		</div>
	</Layout>
);

export default Contact;
