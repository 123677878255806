import React from "react";
import SEO from "../components/seo/js/seo";
import Layout from "../components/layout/js/layout";
import Widget from "../components/widget/js/widget";
import Topics from "../components/topics/js/topics";
import { siteMetadata } from "../../gatsby-config";

const IndexPage = () => (
	<Layout>
		<SEO site={siteMetadata} title="👋 Startseite" />
		<div className="g-container">
			<div className="g-row">
				<div className="g-col-md-6">
					<article className="h-mt-4 h-mb-4">
						<h2>Suchen Sie Unterstützung im Web?</h2>
						<p>
							Suchen Sie Beratung bei der Umsetzung Ihrer webbasierten digitalen Strategien?
							<br />
							Benötigen Sie Unterstützung bei der Realisierung von Webapplikations-Lösungen und
							dynamischer Frontends?
							<br />
							Möchten Sie Ihr Vorhaben interdisziplinär und mit einem dynamischen Team umsetzen?
						</p>
					</article>
					<h3 className="h-mb-3">
						Ich beschäftige mich mit den folgenden Themen, Methoden, Konzepten und Tools
					</h3>
					<Topics />
				</div>
				<div className="g-col-md-6">
					<aside className="h-mt-4 h-mb-4">
						<Widget />
					</aside>
				</div>
			</div>
		</div>
	</Layout>
);

export default IndexPage;
